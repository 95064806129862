/* eslint-disable import/no-extraneous-dependencies */
<template>
  <div id="HomePage">
    <!-- 轮播图 -->
    <div
      id="swiper"
      class="container-fuild"
    >
      <div class="swiper-container banner-swiper">
        <div class="swiper-wrapper">
          <div
            v-for="(item,index) in swiperList"
            :key="index"
            class="swiper-slide"
          >
            <img
              class="swiper-lazy"
              :data-src="item.img"
              alt=""
            >
            <div class="swiper-lazy-preloader" />
            <div class="swiper-slide-title">
              <h1 v-html="item.title" />
              <p v-html="item.content" />
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination" />

        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev" />
        <div class="swiper-button-next" />
      </div>
    </div>

    <!-- 为什么选择我们 -->
    <div
      id="whyChooseUs"
      class="conatiner-fuild"
    >
      <div class="container">
        <div class="whyChooseUs-title text-center">
          <p>为什么选择我们的服务</p>
          <p>THE REASON TO CHOOSING US</p>
        </div>
        <div
          class="row"
          style=""
        >
          <div
            v-for="(item,index) in serverList"
            :key="index"
            class="col-xs-12 col-sm-6 col-md-4 server-wrapper"
          >
            <div
              class="server-block wow slideInUp"
              onmouseenter="this.style.color='#28f';this.style.borderColor='#28f'"
              onmouseleave="this.style.color='#666';this.style.borderColor='#ccc'"
            >
              <p class="text-center">{{ item.title }}</p>
              <img
                class="center-block"
                :src="item.logo"
                alt="logo"
              >
              <div
                class="text-center"
                onmouseenter="this.style.color='#28f'"
                onmouseleave="this.style.color='#ccc'"
                v-html="item.content"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 选址 -->
    <div
      id="bigData"
      class="container-fuild"
    >
      <div class="row bigData-container">
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn">
          <img
            class="img-responsive"
            src="@/assets/img/img1.png"
            alt="大数据选址系统"
            width="100%"
            hidden="100%"
          >
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6">
          <h2 class="bigData-title">
            店驰<small>又准又快的签约好的店铺</small>
          </h2>
          <p>我们让连锁店选址工作：边界清晰、目标明确、完成任务。</p>
          <p>基于数据完成网络规划，基于规划完成开店目标，实现区块、店铺的项目化管理，让选址开发能力成为企业的核心竞争力。</p>
          <p>目前，绝大多数的门店选址开发思想和工具与15年前一模一样。我们帮您升级思想和工具。</p>
          <p>门店是零售企业最重要的流量入口，要靠选址开发团队一个点、一个点的啃下来。但目前，绝大多数企业选址开发部门的开发思想和工具与15年前一模一样。我们要打赢未来的战争先从升级我们的开发思想开始。</p>
          <h2 class="bigData-device">“选对位置，开对店”</h2>
        </div>
      </div>
    </div>
    <div
      id="customer"
      class="container-fuild"
    >
      <div class="container customer-container">
        <p class="customer-title text-center">客户评价</p>
        <div class="swiper-container customer-swiper hidden-xs">
          <div class="swiper-wrapper">
            <div
              v-for="(item,index) in customerList"
              :key="index"
              class="swiper-slide customer-block"
            >
              <div class="customer-logo">
                <img
                  class="center-block"
                  :src="item.logo"
                  alt="logo"
                >
              </div>
              <div class="customer-yh">
                <img
                  src="@/assets/img/yinhao.png"
                  alt="引号"
                >
              </div>
              <div class="customer-content1">
                <small>{{ item.content }}</small>
              </div>
              <div class="customer-content2">{{ item.title }}</div>
            </div>
          </div>
          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev" />
          <div class="swiper-button-next" />
        </div>
        <div class="row visible-xs customer-block">
          <div
            v-for="(item,index) in customerList"
            :key="index"
            class="col-xs-12"
          >
            <div class="customer-logo">
              <img
                class="center-block"
                :src="item.logo"
                alt="logo"
              >
            </div>
            <div class="customer-yh">
              <img
                src="@/assets/img/yinhao.png"
                alt="引号"
              >
            </div>
            <div class="customer-content1">
              <small>{{ item.content }}</small>
            </div>
            <div class="customer-content2">
              <small>{{ item.title }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import { WOW } from 'wowjs'

export default {
  name: 'HomePage',
  data () {
    return {
      swiperList: [
        {
          img: require('@/assets/img/banner1.png'),
          path: '',
          title: '战略性开发选址',
          content: '店驰「拓店组件」和「运营组件」致力于帮助连锁企业的拓展部和运营部选对位置，管好店铺。'
        },
        {
          img: require('@/assets/img/banner2.jpg'),
          path: '',
          title: '又快又准开好店',
          content: '店驰成立6年以来，已经有超过150家企业客户正在使用店驰进行数字化赋能。'
        }
      ],
      customerList: [
        {
          logo: require('@/assets/img/logo_xinjiayi.png'),
          title: '开发经理 易奇',
          content: '主要是两点了，首先第一点，能很有效的节省沟通成本和时间成本，让业务同事把精力更多地放到门面谈判和信息收集上面去，光这个业务同事的时间结余最少能优化百分之二十以上。第二点呢，是信息的保留和传承上面，新业务员与老业务员的这个信息传承，这个系统能为我们节省很多功夫，信息的保留传递也能高效很多。'
        },
        {
          logo: require('@/assets/img/logo_keduo.png'),
          title: '开发总监  李凯',
          content: '店驰的成功，在于引导从业者回归岗位本质，希望ta不仅要做拓展人和营运人的电池，更要实现全方位为零售赋能！'
        },
        {
          logo: require('@/assets/img/logo_xianfengshuiguo.png'),
          title: '发展中心经理 徐贞标',
          content: '店弛软件的引入为我们企业在快速发展提供帮助，特别对新市场的拓展开发提供了非常有效的数据支持。能够标准化、全角度去分析店铺的有效性，并提供了全基盘的数据进行参考。让原先靠经验和人脉去拓店变成靠标准和大数据去拓店，开店成功率大大增加。'
        },
        {
          logo: require('@/assets/img/logo_qiandama.jpg'),
          title: '钱大妈  李元利',
          content: '在引入店驰APP之后，在一定程度上推动了我们公司监管门店的标准化流程，并促进了主管、店长及加盟商之间的沟通联系；这些监管、沟通会让门店的各种问题暴露出来，从而运营管理在决策上更加有依据，并进一步改善门店的经营管理，和提高门店的销售业绩。'
        },
        {
          logo: require('@/assets/img/logo_shengxianchuanqi.jpg'),
          title: '开发部总监 徐晓东',
          content: '店驰的启用，明显的感受到给我们工作带来的变化. 出于对当前大规模拓店和选址成功率的要求，其满足我们对于拓店工作更专业、更系统、更标准化的需求。通过这个平台汇总挖掘资源信息，节省时间成本、沟通成本。'
        },
        {
          logo: require('@/assets/img/logo_youke.png'),
          title: '林亚辉 开发专员',
          content: '方便各项信息的保存工作，能够更好的将资源汇总到一起；规范的采集信息对立店准确率得到进一步提升。提高选址成功率；实现单个店铺谈判流程的汇总；提高开发人员甚至公司的选址能力。'
        }
      ],
      serverList: [
        {
          logo: require('@/assets/img/advantage_1.png'),
          title: '4,200 +',
          content: '<p>帮助企业成功开店</p>'
        },
        {
          logo: require('@/assets/img/advantage_2.png'),
          title: '85% +',
          content: '<p>当前开店成功率</p>'
        },
        {
          logo: require('@/assets/img/advantage_3.png'),
          title: '156',
          content: '<p>使用企业客户数</p>'
        }
      ]
    }
  },
  mounted () {
    /* banner-swiper */
    // eslint-disable-next-line no-new
    new Swiper('.banner-swiper', {
      loop: true, // 循环模式选项
      effect: 'fade',
      // 自动播放
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      // 延迟加载
      lazy: {
        loadPrevNext: true
      },
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true // 修改swiper的父元素时，自动初始化swiper
    })
    /* customer-swiper */
    new Swiper('.customer-swiper', {
      loop: true, // 循环模式选项
      slidesPerView: 3,
      // 自动播放
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true // 修改swiper的父元素时，自动初始化swiper
    })
    /* wowjs动画 */
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    })
    wow.init()
  }
}
</script>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
}

/* 轮播图 */
#swiper {
  height: 600px;
}

#swiper .banner-swiper {
  width: 100%;
  height: 100%;
}

#swiper .banner-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
}

#swiper .banner-swiper .swiper-slide {
  position: relative;
}

#swiper .banner-swiper .swiper-slide-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(51, 51, 51, 0.534);
  text-align: center;
  line-height: 80px;
}

#swiper .banner-swiper .swiper-slide-title > h1 {
  font-size: 50px;
  margin-top: 12%;
}

#swiper .banner-swiper .swiper-slide-title > p {
  font-size: 16px;
  margin-top: 1%;
  /*font-weight: 700;*/
}

/* 选址 */
#bigData {
  padding: 100px;
  transition: all ease 0.6s;
  box-sizing: border-box;
}

#bigData .bigData-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

#bigData p {
  font-size: 14px;
  font-weight: normal;
  color: #333;
  line-height: 2rem;
}

#bigData .bigData-device {
  margin: 50px 0 20px;
}

/* 您身边的选址专家 */
#contactUs {
  color: #fff;
  height: 400px;
  background: url('../assets/img/contact_us_bg.jpg') 0 0 no-repeat;
  background-size: 100% 100%;
  transition: all ease 0.6s;
}

#contactUs .contactUs-container {
  padding-top: 50px;
}

#contactUs .contactUs-container button {
  width: 300px;
  height: 50px;
  margin-top: 40px;
}

#contactUs .contactUs-container .contactUs-contactWay span {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 30px;
}

#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(1) {
  background: url('../assets/img/weixin.png') 0 0 no-repeat;
  background-size: 100% 100%;
}

#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(2) {
  background: url('../assets/img/weibo.png') 0 0 no-repeat;
  background-size: 100% 100%;
}

#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(3) {
  background: url('../assets/img/twitter.png') 0 0 no-repeat;
  background-size: 100% 100%;
}

/* 客户评价 */
#customer {
  padding: 50px 0;
  box-sizing: border-box;
  background: #efefef;
  transition: all ease 0.6s;
}

#customer .customer-title {
  font-size: 30px;
  color: rgb(102, 102, 102);
  margin: 0 0 30px;
}

#customer .customer-block {
  /*background: #fff;*/
  padding: 30px;
}

#customer .customer-logo img {
  height: 50px;
  /*border: 1px solid #ccc;*/
}

#customer .customer-yh img {
  width: 34px;
  height: 34px;
}

#customer .customer-content1 {
  padding-bottom: 20px;
  border-bottom: 1px solid #0ce9f1;
}

#customer .customer-content1 > small {
  font-weight: normal;
  font-size: 14px;
}

#customer .customer-content2 {
  padding-top: 20px;
  float: right;
}

/* 为什么选择我们 */
#whyChooseUs {
  padding-top: 100px;
}

#whyChooseUs .whyChooseUs-title {
  margin-bottom: 50px;
}

#whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
  font-size: 25px;
  font-weight: 500;
}

#whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
  font-size: 14px;
}

#whyChooseUs .server-block {
  /*padding: 50px 20px;*/
  /*border: 1px solid #ccc;*/
  /*border-bottom: 5px solid #ccc;*/
}

#whyChooseUs .server-block img {
  width: 100px;
  height: 100px;
}

#whyChooseUs .server-block > p {
  /*font-size: 20px;*/
  /*margin: 30px 0;*/
  font-size: 60px;
  color: #d3d3d3;
  word-wrap: break-word;
  font-weight: 700;
  word-break: break-all;
  margin: -30px 10px;
  float: left;
}

#whyChooseUs .server-block > div {
  color: #ccc;
}

/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  #swiper {
    height: 200px;
  }

  #bigData {
    padding: 30px;
  }

  #bigData .bigData-title {
    font-size: 20px;
  }

  #bigData .bigData-device {
    font-size: 20px;
    margin: 10px 0 10px;
  }

  #contactUs {
    height: 200px;
    transition: all ease 0.6s;
  }

  #contactUs .contactUs-container {
    padding-top: 0;
  }

  #contactUs .contactUs-container h1 {
    font-size: 25px;
  }

  #contactUs .contactUs-container h3 {
    font-size: 18px;
  }

  #contactUs .contactUs-container button {
    width: 200px;
    height: 30px;
    margin-top: 20px;
  }

  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 10px;
  }

  #customer {
    padding: 30px 0;
    box-sizing: border-box;
    background: #fff;
  }

  #customer .customer-title {
    font-size: 20px;
    font-weight: bold;
  }

  #customer .customer-logo img {
    height: 40px;
  }

  #customer .customer-block {
    padding: 30px;
  }

  #customer .customer-block > div {
    padding: 30px 0;
  }

  #whyChooseUs {
    padding: 20px 0;
    transition: all ease 0.6s;
  }

  #whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
    font-size: 20px;
    font-weight: 700;
  }

  #whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
    font-size: 12px;
  }

  #whyChooseUs .server-block {
    padding: 50px 0;
    border: 1px solid #ccc;
    border-bottom: 5px solid #ccc;
  }

  #whyChooseUs .server-block img {
    width: 48px;
    height: 48px;
  }

  #whyChooseUs .server-block > p {
    font-size: 20px;
    margin: 30px 0;
  }

  #whyChooseUs .server-block > div {
    color: #ccc;
  }
}

/* 媒体查询（平板） */
/*@media screen and (min-width: 767px) and (max-width: 996px) {*/
@media screen and (max-width: 767px) {
  #swiper {
    height: 400px;
  }

  #swiper .banner-swiper .swiper-slide-title > h1 {
    font-size: 25px;
    position: relative;
    top: 150px;
    text-align: right;
    padding-right: 40px;
  }

  #swiper .banner-swiper .swiper-slide-title > p {
    font-size: 16px;
    margin-top: 1%;
    word-break: break-all;
    text-align: left;
    line-height: 35px;
    position: relative;
    float: right;
    padding-right: 35px;
    top: 160px;
    max-width: 300px;
    word-wrap: break-word;
  }

  #bigData {
    padding: 60px;
  }

  #bigData .bigData-title {
    font-size: 30px;
  }

  #bigData .bigData-device {
    font-size: 30px;
    margin: 30px 0 15px;
  }

  #contactUs {
    height: 300px;
  }

  #contactUs .contactUs-container {
    padding-top: 50px;
  }

  #contactUs .contactUs-container h1 {
    font-size: 30px;
  }

  #contactUs .contactUs-container h3 {
    font-size: 20px;
  }

  #contactUs .contactUs-container button {
    width: 300px;
    height: 50px;
    margin-top: 30px;
  }

  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 15px;
  }

  #customer .customer-title {
    font-size: 24px;
  }

  #whyChooseUs {
    padding: 20px 0;
  }
}
</style>
